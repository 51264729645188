import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Polygon,
  Polyline,
  Marker,
} from 'react-google-maps';
import pin_available from '../images/pin-available.png';
import pin_charging from '../images/pin-charging.png';
import pin_damaged_fixed from '../images/pin-damaged-fixed.png';
import pin_damaged_collected from '../images/pin-damaged-collected.png';
import pin_damaged from '../images/pin-damaged.png';
import pin_emergency_pickup from '../images/pin-emergency-pickup.png';
import pin_inaccessible from '../images/pin-inaccessible.png';
import pin_low_battery from '../images/pin-low-battery.png';
import pin_missing from '../images/pin-missing.png';
import pin_picked_up from '../images/pin-picked-up.png';
import pin_pickup_requested from '../images/pin-pickup-requested.png';
import pin_reserved from '../images/pin-reserved.png';
import pin_riding from '../images/pin-riding.png';
import pin_very_low_battery from '../images/pin-low-battery.png';
import pin_start from '../images/pin-start.png';
import scooter_overlay_cancel from '../images/scooter-overlay-cancel.png';

const { MarkerWithLabel } = require('react-google-maps/lib/components/addons/MarkerWithLabel');

const Markers = ({
  places = [],
  restrictedAreasGrey = [],
  restrictedAreasPurple = [],
  restrictedAreasRed = [],
  routeEnds = [],
  routeStarts = [],
  ...props
}) => {
  const map = useRef(null);

  const mapClick = (lat, lng) => props.isClickable && props.mapClick(lat, lng);

  const deleteClick = (vehicle) => props.deleteVehicleClick(vehicle);

  const labelSize = { width: 38 };
  const startLabelSize = { width: 50 };
  const startsArray = routeStarts.map((x, i) => ({
    lat: x[0].lat,
    lng: x[0].lng,
    id: i,
  }));
  const endsArray = routeEnds.map((x, i) => ({
    lat: x[0].lat,
    lng: x[0].lng,
    id: i,
  }));
  const start_location = places[0] && { lat: places[0].location.lat, lng: places[0].location.lng };
  const done_color = '#2CC233';
  const not_done_color = '#F19938';
  const route_color = '#756FDE';

  const razorMapStyles = [
    { stylers: [{ saturation: '-20' }, { lightness: '15' }] },
    { featureType: 'poi.attraction', stylers: [{ saturation: '-100' }] },
    { featureType: 'poi.business', stylers: [{ saturation: '-100' }] },
    {},
    { featureType: 'poi.medical', stylers: [{ saturation: '-100' }] },
    {},
    { featureType: 'poi.place_of_worship', stylers: [{ saturation: '-100' }] },
    { featureType: 'poi.school', stylers: [{ saturation: '-100' }] },
    { featureType: 'poi.sports_complex', stylers: [{ saturation: '-100' }] },
    { featureType: 'road.highway', stylers: [{ saturation: '-20' }, { lightness: '20' }] },
    { featureType: 'transit', stylers: [{ saturation: '-100' }] },
  ];

  return (
    <GoogleMap
      defaultZoom={props.zoom}
      defaultCenter={props.center}
      onCenterChanged={() => props.onDrag(map.current.getCenter())}
      onZoom={() => props.onZoom(map.current.getZoom())}
      onClick={(e) => mapClick(e.latLng.lat(), e.latLng.lng())}
      options={{
        mapTypeControl: false,
        gestureHandling: 'greedy',
        styles: razorMapStyles,
      }}
      ref={map}>
      {places.map((place, index) => {
        let icon_image;
        switch (place.status) {
          case 'emergency_pickup':
            icon_image = pin_emergency_pickup;
            break;
          case 'inaccessible':
            icon_image = pin_inaccessible;
            break;
          case 'low_battery':
            icon_image = pin_low_battery;
            break;
          case 'very_low_battery':
            icon_image = pin_very_low_battery;
            break;
          case 'reserved':
            icon_image = pin_reserved;
            break;
          case 'available':
            icon_image = pin_available;
            break;
          case 'picked_up':
            icon_image = pin_picked_up;
            break;
          case 'damaged':
            icon_image = pin_damaged;
            break;
          case 'pickup_requested':
            icon_image = pin_pickup_requested;
            break;
          case 'damaged_fixed':
            icon_image = pin_damaged_fixed;
            break;
          case 'missing':
            icon_image = pin_missing;
            break;
          case 'riding':
            icon_image = pin_riding;
            break;
          case 'damaged_collected':
            icon_image = pin_damaged_collected;
            break;
          case 'charging':
            icon_image = pin_charging;
            break;
          default:
            icon_image = pin_available;
            break;
        }
        const name_background = place.has_active_ticket ? not_done_color : done_color;
        return (
          <MarkerWithLabel
            labelStyle={{
              textAlign: 'center',
              width: labelSize.width + 'px',
              backgroundColor: name_background,
              fontSize: '10px',
              borderRadius: '8px',
              color: 'white',
              fontWeight: 'bold',
              padding: '2px',
            }}
            labelClass="map-label"
            labelAnchor={{ x: 21, y: -1 }}
            key={`scooter_${index}`}
            position={{ lat: place.location.lat, lng: place.location.lng }}
            optimized="false"
            zIndex={place.zindex}
            icon={{
              url: icon_image,
              anchor: new window.google.maps.Point(21, 40),
              scaledSize: { width: 40, height: 80 },
            }}
            onClick={() => deleteClick(place)}>
            <span>{place.vehicle_name}</span>
          </MarkerWithLabel>
        );
      })}
      {places.map((place, index) => {
        return (
          <Marker
            key={`start_${index}`}
            position={{ lat: place.location.lat, lng: place.location.lng }}
            options={{ icon: scooter_overlay_cancel }}
            onClick={() => deleteClick(place)}
          />
        );
      })}
      {startsArray.map((place, index) => (
        <Marker
          key={`start_${index}`}
          position={{ lat: place.location.lat, lng: place.location.lng }}
          options={{ icon: 'http://maps.google.com/mapfiles/ms/icons/blue.png' }}
        />
      ))}
      {props.startRouteLocation && (
        <Polygon
          path={props.mapZoneBoundary}
          options={{
            fillColor: 'grey',
            fillOpacity: 0.1,
            strokeColor: 'purple',
            strokeOpacity: 1,
            strokeWeight: 3,
          }}
        />
      )}
      {endsArray.map((place, index) => (
        <Marker
          key={`end_${index}`}
          position={{ lat: place.location.lat, lng: place.location.lng }}
          options={{ icon: 'http://maps.google.com/mapfiles/ms/icons/red.png' }}
        />
      ))}
      {props.startRouteLocation && (
        <Marker
          position={{ lat: props.startRouteLocation.lat, lng: props.startRouteLocation.lng }}
          options={{ icon: pin_start }}
        />
      )}
      {restrictedAreasGrey.map((rArea, index) => (
        <Polygon
          key={`polygon_${index}`}
          path={rArea}
          options={{
            fillColor: '#040404',
            fillOpacity: 0.5,
            strokeColor: '#040404',
            strokeOpacity: 1,
            strokeWeight: 1,
          }}
        />
      ))}
      {restrictedAreasRed.map((rArea, index) => (
        <Polygon
          path={rArea}
          key={`restricted_area_red_${index}`}
          options={{
            fillColor: '#903020',
            fillOpacity: 0.6,
            strokeColor: '#090302',
            strokeOpacity: 1,
            strokeWeight: 1,
          }}
        />
      ))}
      {restrictedAreasPurple.map((rArea, index) => (
        <Polygon
          path={rArea}
          key={`restricted_area_purple_${index}`}
          options={{
            fillColor: '#552090',
            fillOpacity: 0.5,
            strokeColor: '#552090',
            strokeOpacity: 1,
            strokeWeight: 1,
          }}
        />
      ))}
      {places[0] && (
        <Polyline
          path={props.routePlaces}
          key="route_place"
          options={{
            strokeColor: route_color,
            strokeOpacity: 1,
            strokeWeight: 2,
          }}
        />
      )}
      {places[0] && !props.startRouteLocation && (
        <MarkerWithLabel
          labelStyle={{
            textAlign: 'center',
            width: startLabelSize.width + 'px',
            backgroundColor: '#2f7cf6',
            fontSize: '12px',
            borderRadius: '8px',
            color: 'white',
            fontWeight: 'bold',
            padding: '2px',
          }}
          labelClass="map-label"
          labelAnchor={{ x: 21, y: 60 }}
          key="1"
          position={start_location}
          optimized="false"
          onClick={() => deleteClick(places[0])}
          icon={{
            url: '',
            anchor: new window.google.maps.Point(21, 40),
            scaledSize: { width: 40, height: 80 },
          }}>
          <span>{'START'}</span>
        </MarkerWithLabel>
      )}
      {props.mapZoneBoundary && (
        <Polygon
          path={props.mapZoneBoundary}
          options={{
            fillColor: 'grey',
            fillOpacity: 0.1,
            strokeColor: 'purple',
            strokeOpacity: 1,
            strokeWeight: 3,
          }}
        />
      )}
    </GoogleMap>
  );
};

Markers.propTypes = {
  center: PropTypes.object,
  deleteVehicleClick: PropTypes.func,
  isClickable: PropTypes.bool,
  mapClick: PropTypes.func,
  mapZoneBoundary: PropTypes.object,
  onDrag: PropTypes.func,
  onZoom: PropTypes.func,
  places: PropTypes.array,
  restrictedAreasGrey: PropTypes.array,
  restrictedAreasPurple: PropTypes.array,
  restrictedAreasRed: PropTypes.array,
  routeEnds: PropTypes.array,
  routePlaces: PropTypes.array,
  routeStarts: PropTypes.array,
  startRouteLocation: PropTypes.object,
  zoom: PropTypes.number,
};

export default withScriptjs(withGoogleMap(Markers));
