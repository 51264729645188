/* eslint-disable no-alert */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from 'react-widgets';
import Loader from '../../Components/Loader';
import { bindActionCreators } from 'redux';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import { DropdownList } from 'react-widgets';
import { connect } from 'react-redux';
import Map from '../../Components/RouteMap';
import { getOpsUsers } from '../../state/users';
import { getRoutes, createFieldTickets, createScheduledRoute } from '../../state/routes';
import { createFieldRoute } from '../../state/field_routes';
import Popup from 'reactjs-popup';
import { deleteFieldRoutes } from '../../state/zones';
import { setSelectedZone } from '../../state/selected_zone';
import { getWarehouse } from '../../state/warehouse';
import { getSubzones } from '../../state/subzones';
import { getZoneVehicles } from '../../state/vehicles';
import moment from 'moment-timezone';
import { getTruckCapacity, getActiveZones } from '../../util/helper';
import { getZoneCenter } from '../../util/helper';

import './index.scss';
import { navigate } from 'gatsby';
import momentLocalizer from 'react-widgets-moment';

moment.locale('en');
momentLocalizer();

class Routes extends Component {
  constructor(props) {
    super(props);
    const selectedZone = this.props.selected_zone
      ? this.props.zones.find((zone) => zone.id === this.props.selected_zone)
      : undefined;
    const hasSavedZone = Boolean(this.props.selected_zone);
    this.state = {
      loaded: true,
      open: false,
      mapPlaces: [],
      mapCenter: {},
      error: null,
      showZoneSelected: false,
      selectedOps: [],
      truckCapacity: 18,
      statuses: {
        pickup_requested: false,
        damaged: false,
        low_battery: false,
        missing: false,
        inaccessible: false,
        reserved: false,
        emergency_pickup: false,
      },
      vehicles: {
        pickup_requested: false,
        damaged: false,
        low_battery: false,
        missing: false,
        inaccessible: false,
        reserved: false,
        emergency_pickup: false,
      },
      statusesArr: [],
      pickup_requested_cnt: 0,
      damaged_cnt: 0,
      low_battery_cnt: 0,
      available_cnt: 0,
      reserved_cnt: 0,
      inaccessible_cnt: 0,
      missing_cnt: 0,
      emergency_pickup_cnt: 0,
      truckAmt: 1,
      max_time: 4,
      low_battery_dwell_time: 3,
      damaged_dwell_time: 5,
      pickup_requested_dwell_time: 5,
      selectedBatteryPct: 0,
      isBatteryPctSelected: false,
      routePlaces: [],
      selectedStalenessOption: '1',
      isStaleSelected: false,
      showMap: false,
      schedule_date: undefined,
      showSchedule: false,
      showScheduleBtn: false,
      showSubzone: false,
      showRoutingBtn: false,
      showTicketingBtn: false,
      showReRunRouteBtn: false,
      showResetAndDeleteBtn: false,
      showZoneSelect: true,
      selectedSubzones: [],
      selectedStartLocationType: 'warehouse',
      selectedStartLocation: undefined,
      hasSetLocation: false,
      activeZones: getActiveZones(this.props.zones, this.props.admin.user_default_zones),
      selectedZone,
      hasSavedZone,
    };
  }

  componentDidMount = () => {
    this.state.selectedZone && this.handleZoneChange(this.state.selectedZone, 'zone_state');
  };

  closePopup = () => this.setState({ open: false, selectedRide: '' });

  setStart = () => {
    this.setState({
      open: false,
    });
  };

  closeStartSelector = () => {
    if (!this.state.hasSetLocation) {
      this.setState({
        startLatitude: undefined,
        startLongitude: undefined,
        selectedStartLocation: undefined,
        open: false,
      });
    } else {
      this.setState({
        open: false,
      });
    }
  };

  createScheduledRoute = async () => {
    if (this.state.selectedOps[0] === undefined) {
      alert('Please select an ops user to assign to route');
    } else {
      if (this.state.statuses.pickup_requested) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'pickup_requested'],
        }));
      }
      if (this.state.statuses.damaged) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'damaged'],
        }));
      }
      if (this.state.statuses.low_battery) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'low_battery'],
        }));
      }
      if (this.state.statuses.reserved) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'reserved'],
        }));
      }
      if (this.state.statuses.missing) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'missing'],
        }));
      }
      if (this.state.statuses.inaccessible) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'inaccessible'],
        }));
      }
      if (this.state.statuses.emergency_pickup) {
        await this.setState((prevState) => ({
          statusesArr: [...prevState.statusesArr, 'emergency_pickup'],
        }));
      }
      const isValid = await this.validateRouteDetails();
      this.setState({
        loaded: true,
      });
      if (isValid) {
        this.setState({
          loaded: false,
        });
        const time_zone_name = this.state.selectedZone.time_zone_name;
        moment.tz.setDefault(time_zone_name);
        const scheduled_date = moment(this.state.schedule_date)
          .tz(time_zone_name)
          .tz('GMT')
          .subtract(10, 'minutes')
          .format('MMM DD YYYY HH:mm:ss');
        const shift_start = moment(this.state.schedule_date)
          .tz('GMT')
          .format('MMM DD YYYY HH:mm:ss');
        const shift_end = moment(this.state.schedule_date)
          .tz('GMT')
          .add(this.state.max_time, 'hours')
          .format('MMM DD YYYY HH:mm:ss');
        const warehouseId = this.props.warehouse.id;
        let selectedBatteryPct = 0;
        let statuses = this.state.statusesArr;
        let staleness_days = 0;
        if (this.state.isStaleSelected) {
          staleness_days = this.state.selectedStalenessOption;
          statuses.push('available');
        }
        if (this.state.isBatteryPctSelected === true) {
          selectedBatteryPct = this.state.selectedBatteryPct;
        } else {
          selectedBatteryPct = 0;
        }
        let includeSubzones = false;
        let subzones = {};
        if (this.state.selectedSubzones.length > 0) {
          includeSubzones = true;
          subzones = {
            subzones: this.state.selectedSubzones,
          };
        }
        const params = {
          zone_id: this.state.selectedZone.id,
          warehouse_id: warehouseId,
          truck_capacity: getTruckCapacity(this.state.truckCapacity),
          status: statuses.toString(),
          shift_start_time: shift_start,
          shift_end_time: shift_end,
          max_time: this.state.max_time,
          damaged_dwell_time: this.state.damaged_dwell_time,
          pickup_dwell_time: this.state.pickup_requested_dwell_time,
          staleness_days: staleness_days,
          created_by: 0,
          assigned_to: this.state.selectedOps[0],
          scheduled_date: scheduled_date,
          selected_battery_pct: selectedBatteryPct,
          include_subzones: includeSubzones,
          subzone_ids: subzones,
        };
        await this.props.actions.createScheduledRoute(params);
        this.setState({
          loaded: true,
        });
        alert('Route successfully scheduled');
        this.reset();
      }
    }
  };

  handleZoneChange = async (selectedZone, callType) => {
    if (!selectedZone) {
      return;
    }
    if (callType !== 'zone_state') {
      //This is being called because a zone is saved as a state var, not because of a change in the dropdown select
      await this.props.actions.setSelectedZone(selectedZone.id);
    }
    let mapCenter = getZoneCenter(selectedZone.name);
    this.setState({
      selectedZone,
      loaded: false,
      IsPostZoneShowing: true,
      mapCenter: mapCenter,
    });
    let pickupRequestedCnt = 0;
    let damagedCnt = 0;
    let lowBatteryCnt = 0;
    let availableCnt = 0;
    let missingCnt = 0;
    let inaccessibleCnt = 0;
    let reservedCnt = 0;
    let emergencyPickupCnt = 0;
    await this.props.actions.getZoneVehicles(selectedZone.id, false);
    await this.props.actions.getOpsUsers(selectedZone.id);
    await this.props.actions.getSubzones(selectedZone.id);
    if (this.props.opsUsers !== undefined) {
      this.props.vehicles.forEach((vehicle) => {
        if (vehicle.status === 'pickup_requested') {
          pickupRequestedCnt++;
        }
        if (vehicle.status === 'damaged') {
          damagedCnt++;
        }
        if (vehicle.status === 'low_battery') {
          lowBatteryCnt++;
        }
        if (vehicle.status === 'available') {
          availableCnt++;
        }
        if (vehicle.status === 'missing') {
          missingCnt++;
        }
        if (vehicle.status === 'reserved') {
          reservedCnt++;
        }
        if (vehicle.status === 'inaccessible') {
          inaccessibleCnt++;
        }
        if (vehicle.status === 'emergency_pickup') {
          emergencyPickupCnt++;
        }
      });
      await this.props.actions.getWarehouse(this.state.selectedZone.id);
      this.setState({
        pickup_requested_cnt: pickupRequestedCnt,
        damaged_cnt: damagedCnt,
        low_battery_cnt: lowBatteryCnt,
        available_cnt: availableCnt,
        missing_cnt: missingCnt,
        reserved_cnt: reservedCnt,
        inaccessible_cnt: inaccessibleCnt,
        emergency_pickup_cnt: emergencyPickupCnt,
        showZoneSelected: true,
        showRoutingBtn: true,
        showScheduleBtn: true,
        includeSubzones: true,
        showSubzone: this.props.subzones.length > 0,
      });
      moment.tz.setDefault(this.state.selectedZone.time_zone_name);
    } else {
      //no ops users in zone so error message given, hide any showing route components
      this.setState({
        showZoneSelected: false,
        showRoutingBtn: false,
        showScheduleBtn: false,
        includeSubzones: false,
        showSubzone: false,
      });
    }
    this.setState({
      loaded: true,
    });
  };

  handleBatteryPctChange = (event) => {
    const val = event.target.checked;
    const name = 'isBatteryPctSelected';
    this.setState({
      [name]: val,
    });
  };

  handleChangeOps = (value) => {
    this.setState((prevState) => ({
      selectedOps: [...prevState.selectedOps, value.id],
    }));
  };

  handleStatusesChange = (event) => {
    const val = event.target.checked;
    const name = event.target.name;
    let updatedStatuses = Object.assign({}, this.state.statuses, { [name]: val });
    this.setState({
      statuses: updatedStatuses,
    });
  };

  handleStalenessChange = (event) => {
    const val = event.target.checked;
    const name = 'isStaleSelected';
    this.setState({
      [name]: val,
    });
  };

  handleStalenessOptionChange = (event) => {
    this.setState({
      selectedStalenessOption: event.target.value,
    });
  };

  handleStartLocationTypeChange = (event) => {
    const newVal = event.target.value;
    this.setState({
      selectedStartLocationType: newVal,
    });
    if (newVal === 'other') {
      this.setState({
        open: true,
      });
    } else {
      this.setState({
        startLatitude: undefined,
        startLongitude: undefined,
        selectedStartLocation: undefined,
        routePlaces: [],
      });
    }
  };

  handleOptionChange = (event) => {
    this.setState({
      selectedOption: event.target.value,
    });
  };

  handleLocationLableClick = () => {
    const startLatitude = this.state.startLatitude;
    const startLongitude = this.state.startLongitude;
    const selectedStartLocation = {
      lat: startLatitude,
      lng: startLongitude,
    };
    this.setState({
      open: true,
      selectedStartLocation: selectedStartLocation,
      hasSetLocation: true,
    });
  };

  loadPopup = () => {
    const isClickable = true;
    return (
      <Fragment>
        <h2> Choose Starting Location: </h2>
        <Map
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIYWGel69VKdzFDmRV3fYZay_pO36GOk&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '600px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          center={this.state.mapCenter}
          zoom={12}
          routePlaces={this.state.routePlaces}
          places={this.state.mapPlaces}
          mapClick={this.mapClick}
          isClickable={isClickable}
          startRouteLocation={this.state.selectedStartLocation}
        />
        <div className="start-selector-btns">
          <input type="button" className="form-btn" onClick={this.setStart} value="Select" />
          <input
            type="button"
            className="form-btn"
            onClick={this.closeStartSelector}
            value="Cancel"
          />
        </div>
      </Fragment>
    );
  };

  validateRouteDetails = async (selectedStatuses) => {
    if (this.state.truckAmt < 1) {
      alert('A route must have at least 1 truck');
      return false;
    } else if (this.state.truckCapacity.length < 1) {
      alert('Please select truck capacity to a number between 1 and 100');
      return false;
    } else if (
      selectedStatuses < 1 &&
      this.state.isStaleSelected === false &&
      this.state.isBatteryPctSelected === false
    ) {
      alert('Please select one or more scooter statuses to assign to route');
      return false;
    } else if (this.state.totalSelectedPickups > 299) {
      alert('The routing system has a max pickup capacity of 300.');
      return false;
    } else {
      return true;
    }
  };

  getRoutes = async () => {
    this.setState({
      loaded: false,
      showScheduleBtn: false,
      showSchedule: false,
    });
    const selectedStatuses = Object.entries(this.state.statuses)
      .filter(([, selected]) => selected)
      .map(([status]) => status);
    const isValid = await this.validateRouteDetails(selectedStatuses);
    this.setState({
      loaded: true,
      statusesArr: selectedStatuses,
    });
    if (isValid) {
      this.setState({
        loaded: false,
      });
      const warehouseId = this.props.warehouse.id;
      const shift_start = moment().tz('America/Los_Angeles').format('MMM DD YYYY HH:mm:ss');
      const shift_end = moment(shift_start)
        .add(this.state.max_time, 'hours')
        .tz('America/Los_Angeles')
        .format('MMM DD YYYY HH:mm:ss');

      let selectedBatteryPct = 0;
      let statuses = this.state.statusesArr;
      let staleness_days = 0;
      if (this.state.isStaleSelected) {
        staleness_days = this.state.selectedStalenessOption;
        statuses.push('available');
      }
      if (this.state.isBatteryPctSelected === true) {
        selectedBatteryPct = this.state.selectedBatteryPct;
      } else {
        selectedBatteryPct = 0;
      }
      let includeSubzones = false;
      if (this.state.selectedSubzones.length > 0) {
        includeSubzones = true;
      }
      const params = {
        zone_id: this.state.selectedZone.id,
        warehouse_id: warehouseId,
        truck_capacity: getTruckCapacity(this.state.truckCapacity),
        truck_count: 1,
        status: statuses,
        shift_start_time: shift_start,
        shift_end_time: shift_end,
        max_time: this.state.max_time,
        low_battery_dwell_time: this.state.low_battery_dwell_time,
        damaged_dwell_time: this.state.damaged_dwell_time,
        pickup_requested_dwell_time: this.state.pickup_requested_dwell_time,
        selected_battery_pct: selectedBatteryPct,
        staleness_days: staleness_days,
        created_by: this.props.admin.id,
        includeSubzones: includeSubzones,
        subzone_ids: this.state.selectedSubzones,
        startLatitude: this.state.startLatitude,
        startLongitude: this.state.startLongitude,
      };
      this.props.actions
        .getRoutes(params)
        .then(() => {
          let waypoint = {};
          let waypoints = [];
          let routeWaypoints = [];
          this.props.routes[0].waypoints.forEach((route, index) => {
            let vehicle_name;
            let vehicle_status;
            if (index > 0) {
              waypoint = {
                lat: route.lat,
                lng: route.lng,
              };
              vehicle_name = this.props.routes[0].names[index - 1];
              vehicle_status = this.props.routes[0].statuses[index - 1][0].status;
              routeWaypoints.push({
                location: waypoint,
                vehicle_name: vehicle_name,
                status: vehicle_status,
                has_active_ticket: 'false',
              });
              if (index === 1) {
                waypoints.push(waypoint);
              }
            }
          });
          let routePlaces = [];
          this.props.routes[0].waypoints.forEach((route, index) => {
            if (index > 0) {
              routePlaces.push({
                lat: route.lat,
                lng: route.lng,
              });
            }
          });
          this.setState({
            mapCenter: waypoints[0],
            routePlaces: routePlaces,
            mapPlaces: routeWaypoints,
            loaded: true,
            showRoutingBtn: false,
            showTicketingBtn: true,
            showSubzone: false,
            showMap: true,
            showZoneSelected: false,
            showZoneSelect: false,
          });
        })
        .catch(() => {
          this.setState({
            loaded: true,
            open: false,
          });
        });
    }
  };

  scheduleRoute = () => {
    this.setState({
      showSchedule: true,
      showScheduleBtn: false,
      showRoutingBtn: false,
    });
  };

  getSubzonesRender = () => {
    return this.props.subzones.map((subzone) => (
      <div className="subzone_checkbox_container" key={subzone.id}>
        <input
          type="checkbox"
          name={subzone.id}
          value={subzone.id}
          onChange={this.handleSubzoneChange.bind(this)}
        />
        <label className="subzone_checkbox">{subzone.name}</label>
      </div>
    ));
  };

  getStartLocationRender = () => {
    if (this.state.startLatitude) {
      const startLatitude = Number.parseFloat(this.state.startLatitude).toFixed(4);
      const startLongitude = Number.parseFloat(this.state.startLongitude).toFixed(4);

      return (
        <label onClick={this.handleLocationLableClick}>
          Other (
          <label className="start-location-label">
            {startLatitude},{startLongitude}
          </label>
          )
        </label>
      );
    }
    return 'Other';
  };

  handleSubzoneChange = (event) => {
    let selected_subzones = this.state.selectedSubzones;
    let check = event.target.checked;
    let checked_subzone = event.target.value;
    if (check) {
      this.setState({
        selectedSubzones: [...this.state.selectedSubzones, checked_subzone],
      });
    } else {
      var index = selected_subzones.indexOf(checked_subzone);
      if (index > -1) {
        selected_subzones.splice(index, 1);
        this.setState({
          selectedSubzones: selected_subzones,
        });
      }
    }
    setTimeout(() => {}, 2000);
  };

  cancelScheduling = () => {
    this.setState({
      showSchedule: false,
      showScheduleBtn: true,
      showRoutingBtn: true,
    });
  };

  createRoute = async () => {
    this.setState({
      loaded: false,
      showMap: false,
      showTicketingBtn: false,
    });
    const params = {
      route_id: this.props.routes[0].route_id[0],
      zone_id: this.state.selectedZone.id,
    };
    await this.props.actions.createFieldRoute(params).catch((error) => {
      if (window.confirm(error)) {
        window.location.reload();
      } else {
        window.location.reload();
      }
    });
    alert('Route has been successfully created');
    this.reset();
  };

  resetFieldTickets = async () => {
    this.setState({
      loaded: false,
    });
    await this.props.actions.deleteFieldRoutes(this.state.selectedZone.id);
    alert('Routes and associated tickets successfully deleted');
    window.location.reload();
  };

  redo = () => {
    this.setState({
      statuses: {},
      statusesArr: [],
      startLatitude: undefined,
      startLongitude: undefined,
      selectedStartLocation: undefined,
      selectedStartLocationType: 'warehouse',
      showRoutingBtn: true,
      showTicketingBtn: false,
      showMap: false,
      selectedSubzones: [],
      showZoneSelected: true,
      showZoneSelect: true,
      showScheduleBtn: true,
      routePlaces: [],
    });
  };

  mapClick = (lat, lng) => {
    const selectedStartLocation = {
      lat: lat,
      lng: lng,
    };
    this.setState({
      startLatitude: lat,
      startLongitude: lng,
      routePlaces: [],
      selectedStartLocation: selectedStartLocation,
    });
  };

  reset = () => navigate('/manage-routes', { state: { zone_id: this.state.selectedZone.id } });

  render() {
    const popup = this.loadPopup;
    const {
      selectedZone,
      showZoneSelected,
      showRoutingBtn,
      showTicketingBtn,
      pickup_requested_cnt,
      damaged_cnt,
      low_battery_cnt,
      missing_cnt,
      reserved_cnt,
      inaccessible_cnt,
      emergency_pickup_cnt,
      available_cnt,
      truckCapacity,
      isBatteryPctSelected,
      showZoneSelect,
      showReRunRouteBtn,
      showMap,
      selectedOps,
      showSchedule,
      showScheduleBtn,
      showSubzone,
    } = this.state;
    const isClickable = false;
    const pickup_requested_label = 'Pickup Requested (' + pickup_requested_cnt + ')';
    const damaged_label = 'Damaged (' + damaged_cnt + ')';
    const low_battery_label = 'Low Battery (' + low_battery_cnt + ')';
    const missing_label = 'Missing (' + missing_cnt + ')';
    const reserved_label = 'Reserved (' + reserved_cnt + ')';
    const inaccessible_label = 'Inaccessible (' + inaccessible_cnt + ')';
    const emergency_pickup_label = 'Emergency Pickup (' + emergency_pickup_cnt + ')';
    return (
      <AuthenticatedPage className="edit_vehicle_container">
        <div className="page-title">Route Generator</div>
        {showZoneSelect && (
          <Fragment>
            <h3>Select Zone</h3>
            <DropdownList
              className="vehicles_container__select"
              data={this.state.activeZones}
              value={selectedZone}
              valueField="id"
              textField="name"
              onChange={this.handleZoneChange}
              placeholder="Select a Zone"
            />
          </Fragment>
        )}
        {showZoneSelected && (
          <Fragment>
            <h3> Pickup Type </h3>
            <input
              type="checkbox"
              name="pickup_requested"
              value={this.state.statuses.pickup_requested}
              onChange={this.handleStatusesChange.bind(this)}
            />
            <label className="form_input_section__label" htmlFor="is_active">
              {pickup_requested_label}
            </label>
            <input
              type="checkbox"
              name="damaged"
              value={this.state.statuses.damaged}
              onChange={this.handleStatusesChange.bind(this)}
            />
            <label className="form_input_section__label" htmlFor="is_active">
              {damaged_label}
            </label>
            <input
              type="checkbox"
              name="low_battery"
              value={this.state.statuses.low_battery}
              onChange={this.handleStatusesChange.bind(this)}
            />
            <label className="form_input_section__label" htmlFor="is_active">
              {low_battery_label}
            </label>
            <input
              type="checkbox"
              name="missing"
              value={this.state.statuses.missing}
              onChange={this.handleStatusesChange.bind(this)}
            />
            <label className="form_input_section__label" htmlFor="is_active">
              {missing_label}
            </label>
            <input
              type="checkbox"
              name="reserved"
              value={this.state.statuses.reserved}
              onChange={this.handleStatusesChange.bind(this)}
            />
            <label className="form_input_section__label" htmlFor="is_active">
              {reserved_label}
            </label>
            <input
              type="checkbox"
              name="inaccessible"
              value={this.state.statuses.inaccessible}
              onChange={this.handleStatusesChange.bind(this)}
            />
            <label className="form_input_section__label" htmlFor="is_active">
              {inaccessible_label}
            </label>
            <input
              type="checkbox"
              name="emergency_pickup"
              value={this.state.statuses.emergency_pickup}
              onChange={this.handleStatusesChange.bind(this)}
            />
            <label className="form_input_section__label" htmlFor="emergecny_pickup">
              {emergency_pickup_label}
            </label>
            <Fragment>
              <br />
              <span className="status-cnts"> Available: {available_cnt} </span>
              <br />
              <input
                type="checkbox"
                name="isBatteryPctSelected"
                value={isBatteryPctSelected}
                onChange={this.handleBatteryPctChange.bind(this)}
              />

              <label className="battery-input-lable" htmlFor="is_active">
                include batteries up to
              </label>
              <input
                type="text"
                className="battery-pct-input"
                placeholder={this.state.selectedBatteryPct}
                name="battery_pct"
                onChange={(e) => this.setState({ selectedBatteryPct: e.target.value })}
              />
              <label className="form_input_section__label" htmlFor="is_active">
                percent
              </label>
            </Fragment>
            <div className="radio">
              <input
                type="checkbox"
                name="isStaleSelected"
                value={this.state.isStaleSelected}
                onChange={this.handleStalenessChange.bind(this)}
              />
              <label className="input_section__label" htmlFor="isStaleSelected">
                include scooters with stale status for at least
              </label>
              <label>
                <input
                  type="radio"
                  value="1"
                  checked={this.state.selectedStalenessOption === '1'}
                  onChange={this.handleStalenessOptionChange}
                />
                24 hours
              </label>
              <label>
                <input
                  type="radio"
                  value="2"
                  checked={this.state.selectedStalenessOption === '2'}
                  onChange={this.handleStalenessOptionChange}
                />
                48 hours
              </label>
              <label>
                <input
                  type="radio"
                  value="3"
                  checked={this.state.selectedStalenessOption === '3'}
                  onChange={this.handleStalenessOptionChange}
                />
                72 hours
              </label>
            </div>
            <br />
            <h3> Truck Capacity (Excludes Low Battery) </h3>
            <input
              type="text"
              className="partialRefundInput"
              placeholder={truckCapacity}
              name="truckCapacity"
              onChange={(e) => this.setState({ truckCapacity: e.target.value })}
            />
            <h3>Maximum Time (hours allowed for route)</h3>
            <input
              type="text"
              className="max-time-input"
              placeholder={this.state.max_time}
              name="max_time"
              onChange={(e) => this.setState({ max_time: e.target.value })}
            />
            <h3>Starting Location </h3>
            <div className="start-type-radio">
              <label className="start-type">
                <input
                  className="start-type-radio"
                  type="radio"
                  value="warehouse"
                  checked={this.state.selectedStartLocationType === 'warehouse'}
                  onChange={this.handleStartLocationTypeChange}
                />
                Warehouse
              </label>
              <label className="start-type">
                <input
                  type="radio"
                  className="start-type-radio"
                  value="other"
                  checked={this.state.selectedStartLocationType === 'other'}
                  onChange={this.handleStartLocationTypeChange}
                />
                {this.getStartLocationRender()}
              </label>
            </div>
          </Fragment>
        )}
        {showSubzone && (
          <Fragment>
            <h3>Select Subzone</h3>
            {this.getSubzonesRender()}
          </Fragment>
        )}
        {showRoutingBtn && (
          <input type="button" value="Get Route" onClick={this.getRoutes} className="form-btn" />
        )}
        {showScheduleBtn && (
          <input
            type="button"
            value="Schedule For Later"
            onClick={this.scheduleRoute}
            className="form-btn"
          />
        )}
        {showReRunRouteBtn && (
          <input type="button" value="Re-Run Route" onClick={this.getRoutes} className="form-btn" />
        )}
        {showTicketingBtn && (
          <Fragment>
            <input
              type="button"
              value="Create Route"
              onClick={this.createRoute}
              className="form-btn"
            />
            <input type="button" value="Redo Route" onClick={this.redo} className="form-btn" />
          </Fragment>
        )}
        {showSchedule && (
          <Fragment>
            <h2>Schedule Route</h2>
            <h3> Select date and time to run route: </h3>
            <div className="date-selector-container">
              <DateTimePicker
                className="schedule_date_picker"
                defaultValue={this.state.schedule_date}
                onChange={(value) => this.setState({ schedule_date: value })}
              />
            </div>
            <h3>Assign to employee </h3>
            <DropdownList
              className="vehicles_container__select"
              data={this.props.opsUsers}
              value={selectedOps[0]}
              valueField="id"
              textField="fullname"
              onChange={this.handleChangeOps}
              placeholder="Select a field ops employee"
            />
            <input
              type="button"
              value="Save"
              onClick={this.createScheduledRoute}
              className="form-btn"
            />
            <input
              type="button"
              value="Cancel"
              onClick={this.cancelScheduling}
              className="form-btn"
            />
          </Fragment>
        )}
        {showMap && (
          <Map
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIYWGel69VKdzFDmRV3fYZay_pO36GOk&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '600px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            center={this.state.mapCenter}
            zoom={12}
            routePlaces={this.state.routePlaces}
            places={this.state.mapPlaces}
            mapClick={this.mapClick}
            isClickable={isClickable}
            startRouteLocation={this.state.selectedStartLocation}
          />
        )}
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closePopup}
          overlayStyle={{ backgroundColor: 'light-grey' }}
          contentStyle={{ maxHeight: '100vh', overflowY: 'auto' }}>
          {popup}
        </Popup>
        <Loader loaded={this.state.loaded} />
      </AuthenticatedPage>
    );
  }
}

Routes.propTypes = {
  actions: PropTypes.object,
  admin: PropTypes.object,
  opsUsers: PropTypes.array,
  routes: PropTypes.array,
  selected_zone: PropTypes.object,
  subzones: PropTypes.array,
  vehicles: PropTypes.array,
  warehouse: PropTypes.object,
  zones: PropTypes.array,
};

const mapStateToProps = (state) => {
  const {
    admin,
    routes,
    zones: { zones },
    users,
    vehicles: { vehicles },
    warehouse: {
      warehouse: [warehouse],
    },
    user,
    fieldRoutes,
    subzones: { subzones },
    selected_zone,
  } = state;
  const restrictedAccess = admin && admin.restricted_access;
  return {
    admin,
    fieldRoutes,
    restrictedAccess,
    routes,
    selected_zone,
    subzones,
    opsUsers: users.ops.filter(({ is_active }) => is_active),
    user,
    vehicles,
    warehouse,
    zones,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getActiveZones,
        getRoutes,
        getZoneVehicles,
        createFieldTickets,
        deleteFieldRoutes,
        getWarehouse,
        createFieldRoute,
        createScheduledRoute,
        getOpsUsers,
        getSubzones,
        setSelectedZone,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
